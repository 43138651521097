/**
 * @generated SignedSource<<824f1be133d1306e08c8cb14d3a7448c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConciergeSettingsPageQuery$variables = {
  companyId: string;
};
export type ConciergeSettingsPageQuery$data = {
  readonly company: {
    readonly conciergeSettings?: {
      readonly " $fragmentSpreads": FragmentRefs<"ConciergeSettingsPageQueryFields">;
    } | null;
    readonly id: string;
  };
};
export type ConciergeSettingsPageQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly conciergeSettings: {
      readonly isConciergeEnabled: boolean;
      readonly isRouteAllInboundsEnabled: boolean;
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type ConciergeSettingsPageQuery = {
  rawResponse: ConciergeSettingsPageQuery$rawResponse;
  response: ConciergeSettingsPageQuery$data;
  variables: ConciergeSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConciergeSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ConciergeSettings",
                  "kind": "LinkedField",
                  "name": "conciergeSettings",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ConciergeSettingsPageQueryFields"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Company",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConciergeSettingsPageQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConciergeSettings",
                "kind": "LinkedField",
                "name": "conciergeSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isConciergeEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRouteAllInboundsEnabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "289c12f3f503c8951bc2acb8b844b80b",
    "id": null,
    "metadata": {},
    "name": "ConciergeSettingsPageQuery",
    "operationKind": "query",
    "text": "query ConciergeSettingsPageQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    id\n    ... on Company {\n      conciergeSettings {\n        ...ConciergeSettingsPageQueryFields\n      }\n    }\n  }\n}\n\nfragment ConciergeSettingsPageQueryFields on ConciergeSettings {\n  isConciergeEnabled\n  isRouteAllInboundsEnabled\n}\n"
  }
};
})();

(node as any).hash = "11d9723740954d703befac41d030c7a5";

export default node;
